import {useEffect, useState} from 'react';
import {IProject} from '@redux/projects/types';


export const useFilterBySelectTag: (data: {
  selectedTagSlug: string | null
  manyTags?: boolean
  initialData: any[]
}) => { wasTagChanged: boolean, items: any[] } = ({selectedTagSlug, initialData = [], manyTags}) => {
  const [wasTagChanged, setWasTagChanged] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>(initialData)

  useEffect(() => {
    setWasTagChanged(false)
    if (manyTags) {
      setItems(selectedTagSlug && initialData ? initialData.filter((p: IProject) => p.tags?.some(t => t?.slug === selectedTagSlug)) : initialData || [])
    } else {
      setItems(selectedTagSlug ? initialData.filter(b => b.tag?.slug === selectedTagSlug) : initialData)
    }
    setTimeout(() => setWasTagChanged(true), 300)
  }, [selectedTagSlug, initialData])

  return {wasTagChanged, items}
}
