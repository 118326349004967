import {useActions} from '@common/hooks/useActions';
import {scrollToElementById} from '@common/utils/scrollToElementById';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {MouseEvent, useEffect, useState} from 'react';


export const useSelectTag = (type: string) => {
  //TODO: вынести select tag в общий редьюсер не завязанный на конкретной сущности
  const [init, setInit] = useState<boolean>(false)
  //@ts-ignore
  const {selectedTagSlug} = useTypedSelector(state => state[type])
  const actions = useActions()

  useEffect(() => {
    setInit(true)
  }, [])

  const setSelectedTagSlug = (slug: string | null, e?: MouseEvent<HTMLAnchorElement>) => {
    if (selectedTagSlug === slug) {
      e?.preventDefault();
      if (window && window.location.hash.includes('#')) {
        // const routeWithoutHash = window.location.hash.replace('#', '')
        history.pushState('', document.title, window.location.pathname + window.location.search)
      }
    }
    init && scrollToElementById(type + '-page-filter')
    if (type === 'blogs') {
      actions.setBlogs({selectedTagSlug: selectedTagSlug !== slug ? slug : null})
    } else {
      actions?.selectProjectTag(selectedTagSlug === slug ? null : slug || '')
    }
  }

  return {selectedTagSlug, setSelectedTagSlug}

}
